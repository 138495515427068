// const { Click } = require("./Events/Click");
const { Submit } = require("./Events/Submit");

const Incept = (target) => {
  if (!target) return;

  if (typeof target.dataset?.incepted !== "undefined") return;

  target.dataset = {
    ...target?.dataset,
    incepted: true,
  };

  target.querySelectorAll("form").forEach(Submit);

  // target.querySelectorAll("a, button[data-event]").forEach(Click);
  // target.querySelectorAll("[data-iframe]").forEach((target) => {
  //   setTimeout(
  //     insertHiddenIframe,
  //     1000,
  //     target.attributes["data-iframe"].value
  //   );
  // });
  // target.querySelectorAll("[data-render]").forEach((target) => {
  //   if (target?.dataset?.rendered) return;
  //   const name = target?.dataset?.render;
  //   // Component({ name, target }).then((component) => {
  //   //   target.dataset.rendered = true;
  //   // });
  // });
  // target
  //   .querySelectorAll("[data-collection][data-id][data-set]")
  //   .forEach((target) => {
  //     const { collection, set, value } = target.dataset;

  //     target.addEventListener("click", async (event) => {
  //       const id = target.dataset.id;
  //       const modifier = {
  //         $set: {
  //           [set]: value,
  //         },
  //       };
  //       console.log("modifier", modifier, target.dataset);
  //       await DB(collection).updateOne({ _id: { $oid: id } }, modifier);
  //     });
  //   });

  // target.querySelectorAll("[data-toggle]").forEach((target) => {
  //   const toggle_target = document.querySelector(target.dataset.toggle);

  //   if (!toggle_target) return;

  //   target.addEventListener("click", (event) => {
  //     toggle_target.classList.toggle("hidden");
  //   });
  // });

  // target.querySelectorAll("[data-action]").forEach((target) => {
  //   const data = target.dataset;

  //   target.addEventListener("click", async (event) => {
  //     const { id, set, value } = data;

  //     const modifier = {
  //       $set: {
  //         [set]: value,
  //       },
  //     };
  //     console.log("modifier", modifier, data);
  //     await DB("behaviors").updateOne({ _id: { $oid: id } }, modifier);
  //   });
  // });
};
// const loadedIframes = [];
// const insertHiddenIframe = (url) => {
//   return;
//   if (loadedIframes.includes(url)) return;

//   loadedIframes.push(url);

//   // Create a new iframe element
//   var iframe = document.createElement("iframe");

//   // Set the src attribute to the provided URL
//   iframe.src = url;

//   // Set width and height to 0
//   iframe.width = "0";
//   iframe.height = "0";

//   // Set CSS styles to make the iframe invisible
//   iframe.style.display = "none";
//   iframe.style.visibility = "hidden";

//   // Append the iframe to the end of the body element
//   document.body.appendChild(iframe);
// };

module.exports = { Incept };
