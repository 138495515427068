const { GoTo } = require("../../GoTo");

const Submit = (target) => {
  if (target?.dataset?.incepted) return;
  target.dataset.incepted = true;

  target.addEventListener("submit", async (e) => {
    console.log("submit", target.name, e);
    e.preventDefault();

    target.querySelector(".error")?.classList.add("hidden");

    let data = serialize(e.target, true);

    // switch (target.name) {
    //   case "user.signin":
    //     await SignIn(data);
    //     break;
    //   case "user.signup":
    //     await SignUp(data);
    //     break;
    //   case "user.update":
    //     await UserUpdate(data);
    //     break;
    //   default:
    //     break;
    // }

    data = serialize(e.target);

    const thread = await AutoAI.User.track({
      name: "submit",
      event: e,
      data,
    });

    const action = target.getAttribute("action");

    if (!action) return;

    const url = action.replace(/\/$/, "") + "/" + thread.insertedId.toString();

    console.log("goto", url);

    GoTo({
      url,
      event: e,
      event_name: "submit",
      data,
    });
  });
};

const serialize = (t, uncensored = false) => {
  const formData = {};
  Array.from(t.elements).forEach((element) => {
    if (element.name && element.type !== "submit") {
      if (!uncensored && element.type === "password") {
        formData[element.name] = element.value.replace(/./g, "*");
      } else {
        formData[element.name] = element.value;
      }
    }
  });
  return formData;
};

module.exports = { Submit };
