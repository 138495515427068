const { createTracker } = require("./Tracker");
const { createReader } = require("./Read");

const Identity = async ({ App }) => {
  if (!App?.currentUser) {
    return;
  }

  let user = App.currentUser;
  if (!user) {
    try {
      user = await App.logIn(Realm.Credentials.anonymous());
      await App.currentUser.refreshCustomData();
    } catch (error) {
      console.error(error);
    }
  }

  const track = createTracker({ App });
  const read = createReader({ App });

  const UserUpdate = async (modifier) => {
    if (!modifier) return;

    await App.DB("users")?.updateOne(
      { user_id: App.currentUser?.id },
      modifier
    );

    await App.currentUser?.refreshCustomData();
    return;
  };

  if (!user?.customData?.landing_page) {
    UserUpdate({
      $set: { landing_page: window.location.href },
    });
  }

  const signIn = async (data) => {
    const { email, password } = data;

    const credentials = GetCredentials(email, password);

    await App.logIn(credentials);

    track({ name: "signed_in", event });
  };

  const signUp = async (data) => {
    const { email, password } = data;

    const credentials = GetCredentials(email, password);

    await App.emailPasswordAuth.registerUser({
      email,
      password,
    });
    await App.currentUser.linkCredentials(credentials);

    UserUpdate({
      $set: {
        email,
        role: "lead",
        lead_at: new Date(),
        lead_page: window.location.href,
      },
    });
    track({ name: "signed_up", event });
  };

  function GetCredentials(email, password) {
    if (!isValidEmail(email)) throw new Error("Invalid email");

    const credentials = Realm.Credentials.emailPassword(email, password);
    return credentials;
  }

  function isValidEmail(email) {
    const emailRegex =
      /^[\w-]+(\+[\w-]+)*(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  }

  return {
    signIn,
    signUp,
    track,
    read,
  };
};

module.exports = { Identity };
