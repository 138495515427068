const { Fetch } = require("../Fetch");
const { Render } = require("./Render");
const { Incept } = require("./Incept");

let currentPage;

async function getAndRender({ url, target, operation = "inner" }) {
  const content = await Fetch({ url });
  console.log("getAndRender", { url, target, operation, content });
  return Render({ content, target, operation });
}

async function Page({ App }) {
  Incept(document);
  const target = "body > main";
  const pathname = window.location.pathname;
  const parts = pathname.split("/");

  const _id = parts.slice(0, 4).join("/");
  console.log("Page", _id);
  await getTool({ App, _id, target, page: true });
}

async function getTool({ App, _id, target, page = false }) {
  if (_id === currentPage) return;
  if (page) currentPage = _id;
  const tool = await App.DB("pages").findOne({ _id });

  console.log("TOOLS", _id, target, tool);
  const content = tool?.client?.["index.html"];

  if (content) Render({ content, target });
}

async function Component({ App, name, _id, target, operation = "inner" }) {
  if (_id) {
    await getTool({ App, _id, target });
    return;
  }
  url = `/app/components/${name}/index.html`;
  return getAndRender({ url, target, operation });
}

module.exports = { Page, Component, Render };
