const { Realm } = require("./lib/realm-web.js");
const { Page, Component, Render } = require("./lib/Incept/index.js");
const { Identity } = require("./lib/Identity.js");
const { GoTo } = require("./lib/GoTo.js");

window.dataLayer = window.dataLayer || [];

const init = async ({ appId }) => {
  const App = new Realm.App({ id: appId });

  App.DB = (collection) => {
    return App?.currentUser
      ?.mongoClient("mongodb-atlas")
      ?.db("1dollar-ai-production")
      ?.collection(collection);
  };

  const User = await Identity({ App });

  await Page({ App });

  User.track({ name: "page_view" });

  const UI = { Component, Render };

  const AutoAI = {
    App,
    User,
    UI,
    GoTo,
  };

  window.AutoAI = AutoAI;

  return AutoAI;
};

window.AutoAI = { init };

const queryParams = new URLSearchParams(window.location.search);

const in_progress_animation =
  '<svg width="24" height="24" stroke="#0071e3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_V8m1{transform-origin:center;animation:spinner_zKoa 2s linear infinite}.spinner_V8m1 circle{stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite}@keyframes spinner_zKoa{100%{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}</style><g class="spinner_V8m1"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle></g></svg>';

// App.DB = DB;

// function percentage(num, decimalPlaces) {
//   // Ensure the input is a number
//   if (typeof num !== "number") {
//     return "Invalid input";
//   }

//   if (typeof decimalPlaces !== "number") {
//     decimalPlaces = num < 0.1 ? 1 : 0;
//   }

//   // Multiply by 100 and fix to the specified decimal places
//   const percentage = (num * 100).toFixed(decimalPlaces);

//   // Return as a string with a percentage sign
//   return `${percentage}%`;
// }

// async function renderPipeline(collection, pipeline, selector, component) {
//   try {
//     const result = await DB(collection).aggregate(pipeline);

//     if (!result.length) {
//       return;
//     }

//     const target = document.querySelector(selector);

//     if (typeof component !== "function") {
//       return result;
//     }

//     result.forEach((data) => {
//       const html = component(data);

//       updateElementWithHTML(target, html, "append");
//     });
//   } catch (error) {
//     console.error(error);
//   }
// }
